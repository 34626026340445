import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import { useSelectedMainContract, useSelectFeatures, useSelectLicensesByServiceGroup, useSelectLoading } from "../../../../redux/store";
import { FEATURES_FLAG, ID_DATA_ENTITY, ID_STATUS_SERVICES } from "../../../../constants/configs/config.constants";
import { LicenseAndServiceContract } from "compax-api";
import { NfoStatus } from "../../../../../core/components/status.component";
import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../core/utility/themeContext";
import cs from "classnames";
import "./licensesTable.scss";
import { NfoSwitchAllProducts } from "../../../switchActiveProducts/switchActiveProducts.component";
import { NfoEmptyState } from "../../../../../core/emptyState.component";
import { CpxButton } from "../../../../../core/components/button.component";
import { ReactComponent as Add } from '../../../../assets/icons/add.svg';
import { AddLicensesModal } from "../addLicensesModal/addLicensesModal.component";
import { ReduceLicensesModal } from "../reduceLicensesModal/reduceLicensesModal.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { useDispatch } from "react-redux";


export const NfoLicenseTable = ({ title, loadLicenses }: any) => {
  const translations = useTranslations(TRANSLATIONS.licenses);
  const theme = useTheme();
  const internalClassName = 'licenseTable';
  const licenses = useSelectLicensesByServiceGroup();
  const features: any = useSelectFeatures();
  const [filteredItems, setFilteredItems] = useState(licenses)
  const dispatch = useDispatch();
  const selectedMainContract = useSelectedMainContract();

  const actionString = ACTION_CONST.API_GET_LICENSES_BY_SERVICE_GROUP;
  const [isLoading, setIsLoading] = useState(false)

  const [addModal, setAddModal] = useState({ isShowing: false, page: 1, serviceGroup: {} });
  const [reduceModal, setReduceModal] = useState({ isShowing: false, page: 1, option: {} });

  const loadingState: any = useSelectLoading();
  useEffect(() => {
    if (actionString) {
      setIsLoading(loadingState[actionString])
    } else {
      setIsLoading(false)
    }
  }, [loadingState]);

  const groupFilteredItems = filteredItems.reduce((group: any, option: any) => {
    const { description } = option.serviceGroup;
    group[description] = group[description] ?? [];
    group[description].push(option);
    return group;
  }, {});

  const sortedKeys = Object.keys(groupFilteredItems).sort();

  const sortedGroupFilteredItems = sortedKeys.reduce((sortedGroup: any, key: string) => {
    sortedGroup[key] = groupFilteredItems[key];
    return sortedGroup;
  }, {});


  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_LICENSE_EDITABLE }))
  }, [])

  useEffect(() => {
    setLicensesByServiceGroup(sortedGroupFilteredItems)
  }, [filteredItems])


  const [licensesByServiceGroup, setLicensesByServiceGroup] = useState(sortedGroupFilteredItems);

  const licensesTableRowData = (contract: LicenseAndServiceContract) => {

    return {
      id: contract.id ? contract.id + '' : contract.product?.id,
      cellData: [
        <p
          className={cs('tableFirstChild', `tableFirstChild-font-le--${theme}`)}
        >
          {contract?.product?.description}
        </p>,
        <>
          {contract.quantityModel ? (
            contract.quantity
          ) : (
            <p>{translations.unlimited()}</p>
          )}
        </>,
        <NfoStatus
          entity={{ id: ID_DATA_ENTITY.SERVICE }}
          status={contract.status}/>,
        <>
          {features[FEATURES_FLAG.PORTAL_LICENSE_EDITABLE] && (contract?.status?.id === ID_STATUS_SERVICES.ACTIVE && !contract.included) &&
            <CpxButton buttonType={"secondary"} onClick={() => setReduceModal({ isShowing: true, page: 0, option: contract })}>{translations.reduce()}</CpxButton>}
          {features[FEATURES_FLAG.PORTAL_LICENSE_EDITABLE] && contract?.included && translations.included()}
        </>
      ],
    }
  }

  return (
    <>
      <NfoEmptyState
        data={licenses}
        pageTitle={title}
        boxStyling={true}
      >
        {addModal.isShowing && <AddLicensesModal addModal={addModal} setAddModal={setAddModal} loadLicenses={loadLicenses}/>}
        {reduceModal.isShowing && <ReduceLicensesModal reduceModal={reduceModal} setReduceModal={setReduceModal} loadLicenses={loadLicenses}/>}
        <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
          <NfoSwitchAllProducts data={licenses} getFilteredDataBack={(data: any) => setFilteredItems(data)}/>
          {isLoading && <CpxPaginatedTable
            id="licensesSkeletonTable"
            tableHeader={[
            { label: translations.title(), sort: true},
            { label: translations.number(), sort: false },
            { label: translations.status(), sort: true}
          ]}
            tableData={[]}
            rowData={[]}
            actionString={actionString}
            getSortedRowsBack={(sortedRows: any) => {}}
            />}
          <NfoEmptyState data={filteredItems} pageTitle={translations.activeLicenses()} boxStyling={false}>
            {!isLoading && Object.entries(licensesByServiceGroup).map(([key, values]: any) => (
                <div>
                  <h4 className={cs(`${internalClassName}-licenseTitle`, `${internalClassName}-licenseTitle-le--${theme}`)}>{key}</h4>
                  <CpxPaginatedTable
                    id="licensesTable"
                    tableHeader={[
                      { label: key, sort: true, sortBy: 'contractName', sortMethod: 'string' },
                      { label: translations.number(), sort: false },
                      { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
                      features[FEATURES_FLAG.PORTAL_LICENSE_EDITABLE] && selectedMainContract?.status?.id === 1 && {
                        label: <CpxButton className={"buttonIcons"} onClick={() => setAddModal({
                          isShowing: true,
                          page: 1,
                          serviceGroup: { id: values[0].serviceGroup.id, name: key, componentOf: values[0].componentOf }
                        })}><Add/>{translations.add()}</CpxButton>, sort: false
                      },
                    ]}
                    tableData={values?.map(licensesTableRowData)}
                    rowData={values}
                    getSortedRowsBack={(sortedRows: any) => setLicensesByServiceGroup({ ...licensesByServiceGroup, [key]: sortedRows })}
                  />
                </div>
              )
            )}
          </NfoEmptyState>
        </article>
      </NfoEmptyState>
    </>
  );
};
