import { useDispatch } from "react-redux";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../core/utility/themeContext";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useCommissionBillTable } from "../../../utils/bills/utils";
import { validate } from "../../../../core/utils";
import cs from "classnames";
import { CpxDownloadTable } from "../../../../core/components/downloadTable.component";
import { CpxDownload } from "../../../../core/components/download.component";
import React from "react";
import "./commissionTable.scss"

export const NfoCommissionTable = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.commission);
  const theme = useTheme();

  const getCommissionBillCSVFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: {
        billFile: { file: string }
      } = await dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_COMMISSION_BILLS_CSV,
          { commissionBillId: id }
      ))
      return `${response.billFile?.file}`;
    }
  }

  const getCommissionBillPDFFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: {
        billFile: { file: string }
      } = await dispatch(apiCallAction(ACTION_CONST.API_GET_PARTNER_COMMISSION_BILLS_PDF,
          { commissionBillId: id }
      ))
      return `${response.billFile?.file}`;
    }
  }

  const tableData: any = useCommissionBillTable([
    {
      getterCreator: getCommissionBillCSVFile,
      filenameGetter: bill => validate(bill.fileName + '.csv'),
      downloadType: "application/csv",
    },
    {
      getterCreator: getCommissionBillPDFFile,
      filenameGetter: bill => validate(bill.fileName + '.pdf'),
      downloadType: "application/pdf",
    },
  ]);
  return (
    <>
      <article className={cs('commissionTable', `commissionTable-le--${theme}`)}>
        <CpxDownloadTable
          title={translations.title()}
          headers={[
            { label: translations.tableHeadingDate(), sort: true, sortBy: 'cols.0', sortMethod: 'date' },
            { label: translations.tableHeadingName(), sort: true, sortBy: 'cols.1', sortMethod: 'string' },
            { label: translations.tableHeadingBillNumber(), sort: true, sortBy: 'cols.2', sortMethod: 'string' },
          ]}
          tableData={tableData ? tableData : []}
          actionString={ACTION_CONST.API_GET_PARTNER_COMMISSION_DATA}
          downloadsProps={[
            {
              linkName: (
                <>
                  <CpxDownload/>
                  {translations.csvLinkName()}
                </>
              ),
              zipLinkName: translations.allCsvsName(),
              zipFileName: translations.zipFileNameCsv(),
            },
            {
              linkName: (
                <>
                  <CpxDownload/>
                  {translations.pdfLinkName()}
                </>
              ),
              zipLinkName: translations.allPdfsName(),
              zipFileName: translations.zipFileNamePdf(),
            },
          ]}
        />
      </article>
    </>
  )
}
