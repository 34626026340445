import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveCurrentStep, saveStep1Data, saveStep1DifferentContactPerson } from "../../../../redux/actions/step.action";
import { useCurrentOrderType, useCurrentStep, useSelectError, useSelectLang, useSelectLanguage, useSelectPartners, useSelectSalutations, useSelectUserLegalEntity, useStep1Data, useStepperStep1DifferentContactPerson, } from "../../../../redux/store";
import { accountNeeded, trimStringProperties, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import "./step.scss";
import { ContactPersonForm } from "./forms/ContactPersonForm";
import { useFormik } from "formik";
import { areaCodeCheck, areaCodeRequiredCheck, cliCheck, cliRequiredCheck, countryCodeCheck, countryCodeRequiredCheck, createValidatorSchema, emailCheckReq, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { Alert } from "../../../common/Alert/Alert.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearError } from "../../../../redux/actions/error.action";
import { CLIENTS_IDS, INPUT_MAX_LENGTHS, LANGUAGES_ID, ORDER_TYPE } from "../../../../constants/configs/config.constants";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { NfoGenerateFakeDataComponent } from "../generateFakeData/generateFakeData.component";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { PortalPartnerData } from "compax-api";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const NfoContactPersonsStep = () => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch: AppDispatch = useDispatch();
  const currentLang = selectCurrentLanguage(useSelectLang());
  const currentStep = useCurrentStep();
  const dataOfStep1 = useStep1Data();
  const ErrorsOfBE = useSelectError();
  const differentContactPerson = useStepperStep1DifferentContactPerson();
  const salutations = useSelectSalutations();
  const languages = useSelectLanguage();
  const requiredMsg = translation.fieldRequired();
  const client = useSelectUserLegalEntity();
  const orderType = useCurrentOrderType();
  const partners = useSelectPartners() as Array<PortalPartnerData>;

  const technicalContactPersonTemp = dataOfStep1?.contactPersons?.find((cp:any) => +cp.role.id === 113);
  const commercialContactPersonTemp = dataOfStep1?.contactPersons?.find((cp:any) => +cp.role.id === 134);
  const [isDifferentContactPerson, setIsDifferentContactPerson] = useState(differentContactPerson);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_COUNTRY));
    dispatch(apiCallAction(ACTION_CONST.API_GET_SALUTATIONS));
    dispatch(apiCallAction(ACTION_CONST.API_GET_LANGUAGES));
  }, [currentLang]);

  useEffect(() => {
    // check if both contact persons are equal
    const filteredTechnicalContactPersonTemp = {...technicalContactPersonTemp};
    const filteredCommercialContactPersonTemp = {...commercialContactPersonTemp};

    delete filteredTechnicalContactPersonTemp.role;
    delete filteredTechnicalContactPersonTemp.createPortalUser;
    delete filteredCommercialContactPersonTemp.role;
    delete filteredCommercialContactPersonTemp.createPortalUser;

    if (commercialContactPersonTemp && commercialContactPersonTemp.email !== "") {
      if (JSON.stringify(filteredTechnicalContactPersonTemp) !== JSON.stringify(filteredCommercialContactPersonTemp)) {
        setIsDifferentContactPerson(true)
      }
    }
  }, [])

  useEffect(() => {
    dispatch(saveStep1DifferentContactPerson(isDifferentContactPerson))
  }, [isDifferentContactPerson])

  const goForward = () => {
    if(orderType === ORDER_TYPE.ORDER_NEW_CUSTOMER_QUOTE || !accountNeeded(partners[0])){
      dispatch(saveCurrentStep(currentStep + 2));
    }else{
      dispatch(saveCurrentStep(currentStep + 1));
    }
  }

  const salutationOptions = Array.isArray(salutations) ? salutations.map(salutation => {
    return { name: salutation.description, id: salutation.id }
  }) : [];

  const contactLanguageOptions = Array.isArray(languages) ?
    (client.id == CLIENTS_IDS.DTS ?
      languages.filter(l => l.id == LANGUAGES_ID.DE).map(language => {
        return { name: language.description, id: language.id }
      }) :
      languages.map(language => {
        return { name: language.description, id: language.id }
      }))
    : [];

  const formik = useFormik({
    enableReinitialize: !dataOfStep1?.migrationCustomerId,
    initialValues: {
      // contact person
      contactPersons: {
        technicalContactPerson: {
          salutation: {
            id: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.salutation !== undefined && technicalContactPersonTemp?.salutation.id) || salutationOptions[-1]?.id
          },
          firstName: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.firstName) || '',
          lastName: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.lastName) || '',
          email: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.email) || '',
          mobile: {
            countryCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.countryCode) || '',
            areaCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.areaCode) || '',
            cli: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.cli) || '',
            phoneType: {
              id: 2
            },
          },
          phone: {
            countryCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.countryCode) || '',
            areaCode: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.areaCode) || '',
            cli: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.cli) || '',
            phoneType: {
              id: 1
            },
          },
          language: {
            id: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.language?.id)|| contactLanguageOptions[-1]?.id || contactLanguageOptions.find(language => language.id === LANGUAGES_ID.DE)?.id,
          },
          role: {
            id: '113'
          }
        },
        commercialContactPerson: {
          salutation: {
            id: (dataOfStep1?.contactPersons && technicalContactPersonTemp?.salutation !== undefined && technicalContactPersonTemp?.salutation.id) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.salutation !== undefined && commercialContactPersonTemp?.salutation.id) || salutationOptions[-1]?.id
          },
          firstName: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.firstName)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.firstName) || '',
          lastName: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.lastName)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.lastName) || '',
          email: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.email)) ||  (dataOfStep1?.contactPersons && commercialContactPersonTemp?.email) || '',
          login: (dataOfStep1?.contactPersons && commercialContactPersonTemp?.email) || '',
          mobile: {
            countryCode: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.countryCode)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.mobile?.countryCode) || '',
            areaCode: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.areaCode)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.mobile?.areaCode) || '',
            cli: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.mobile?.cli)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.mobile?.cli) || '',
            phoneType: {
              id: 2
            },
          },
          phone: {
            countryCode: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.countryCode)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.phone?.countryCode) || '',
            areaCode: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.areaCode)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.phone?.areaCode) || '',
            cli: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.phone?.cli)) || (dataOfStep1?.contactPersons && commercialContactPersonTemp?.phone?.cli) || '',
            phoneType: {
              id: 1
            },
          },
          language: {
            id: (!isDifferentContactPerson && (dataOfStep1?.contactPersons && technicalContactPersonTemp?.language?.id)) || (dataOfStep1?.contactPersons && technicalContactPersonTemp?.language?.id)|| contactLanguageOptions[-1]?.id,
          },
          role: {
            id: '134'
          }
        }
      },
    },
    validationSchema: createValidatorSchema({
      // contact person
      contactPersons: createValidatorSchema({
        technicalContactPerson: createValidatorSchema({
          salutation: createValidatorSchema({
            id: requiredCheck(requiredMsg),
          }),
          firstName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.firstName, requiredCheck(requiredMsg)),
          lastName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.lastName, requiredCheck(requiredMsg)),
          email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheckReq(requiredMsg, translation.emailSyntax())),
          mobile: createValidatorSchema({
            countryCode: countryCodeRequiredCheck(translation.countryCodeRequired(), translation.countryCodeSyntax()),
            areaCode: areaCodeRequiredCheck(translation.areaCodeRequired(), translation.areaCodeSyntax()),
            cli: cliRequiredCheck(translation.cliRequired(), translation.cliSyntax()),
          }),
          phone: createValidatorSchema({
            countryCode: countryCodeCheck(translation.countryCodeSyntax()),
            areaCode: areaCodeCheck(translation.areaCodeSyntax()),
            cli: cliCheck(translation.cliSyntax()),
          }),
          language: createValidatorSchema({
            id: requiredCheck(requiredMsg),
          }),
        }),
        commercialContactPerson: createValidatorSchema({
          //login: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheckReq(requiredMsg, translation.emailSyntax())),
          salutation: createValidatorSchema({
            id: isDifferentContactPerson && requiredCheck(requiredMsg),
          }),
          firstName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.firstName, isDifferentContactPerson && requiredCheck(requiredMsg)),
          lastName: MaxLengthCheck(INPUT_MAX_LENGTHS.contactPersons.lastName, isDifferentContactPerson && requiredCheck(requiredMsg)),
          email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, isDifferentContactPerson && emailCheckReq(requiredMsg, translation.emailSyntax())),
          mobile: createValidatorSchema({
            countryCode: isDifferentContactPerson && countryCodeCheck(translation.countryCodeSyntax()),
            areaCode: isDifferentContactPerson && areaCodeCheck(translation.areaCodeSyntax()),
            cli: isDifferentContactPerson && cliCheck(translation.cliSyntax()),
          }),
          phone: createValidatorSchema({
            countryCode: isDifferentContactPerson && countryCodeCheck(translation.countryCodeSyntax()),
            areaCode: isDifferentContactPerson && areaCodeCheck(translation.areaCodeSyntax()),
            cli: isDifferentContactPerson && cliCheck(translation.cliSyntax()),
          }),
          language: createValidatorSchema({
            id: isDifferentContactPerson && requiredCheck(requiredMsg),
          }),
        })
      }),
    }),

    onSubmit: async (values: any) => {

      const commercialContactPerson = {...values.contactPersons.commercialContactPerson, login: values.contactPersons.commercialContactPerson.email}
      const technicalContactPerson = values.contactPersons.technicalContactPerson;
      const contactPersonsArray = [
        isDifferentContactPerson ? commercialContactPerson : {...technicalContactPerson, role: { id: '134' }, login: values.contactPersons.technicalContactPerson.email},
        technicalContactPerson
      ];

      contactPersonsArray.map((cp: any) => {
        if (cp.phone?.countryCode === '' && cp.phone.areaCode === '' && cp.phone?.cli === '') {
          cp.phone = undefined;
        }
        if (cp.mobile?.countryCode === '' && cp.mobile.areaCode === '' && cp.mobile?.cli === '') {
          cp.mobile = undefined;
        }
      })

      const finalValues = {
        ...dataOfStep1,
        contactPersons: contactPersonsArray,
        stepper: "CONTACT_PERSONS"
      }

      const tempValues = {
        customer: {...dataOfStep1.customer},
        mainAddress: {...dataOfStep1.mainAddress},
        contactPersons: contactPersonsArray,
        stepper: "CONTACT_PERSONS"
      }

      trimStringProperties(finalValues);
      trimStringProperties(tempValues);

     dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, { ...tempValues, isQuote: false }))
       .then(() => {
          dispatch(clearError())
          dispatch(saveStep1Data(finalValues));
          goForward();
       })
       .catch(() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }))
    }
  });

  return (
    <>
      <NfoCustomerWrapperComponent
        title={translation.contactPersons()}
        subtitle={translation.customerDataSubTitle()}
      >
        <>
          <CpxFormikForm handleSubmit={formik.handleSubmit}
                         initialValues={formik.initialValues}
                         id={'new-customer-form'}
          >
            <NfoGenerateFakeDataComponent salutationOptions={salutationOptions} contactLanguageOptions={contactLanguageOptions}/>
            <ContactPersonForm values={formik.values} handleChange={formik.handleChange}
                               touched={formik.touched} errors={formik.errors} isDifferentContactPerson={isDifferentContactPerson} setIsDifferentContactPerson={setIsDifferentContactPerson}
                               salutationOptions={salutationOptions} contactLanguageOptions={contactLanguageOptions}
            />

          </CpxFormikForm>
          {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 &&
            ErrorsOfBE?.requestData?.currentData?.stepper === "CONTACT_PERSONS" && <Alert errors={ErrorsOfBE.errorData}/>}
        </>
      </NfoCustomerWrapperComponent>
    </>
  );
};
