import React from "react";
import "./cdrTable.scss"
import cs from "classnames";
import { CpxDownloadTable } from "../../../../core/components/downloadTable.component";
import { CpxDownload } from "../../../../core/components/download.component";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTheme } from "../../../../core/utility/themeContext";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { useCDRTable } from "../../../utils/bills/utils";
import { validate } from "../../../../core/utils";

export const NfoCDRTable = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.sdr);
  const theme = useTheme();

  const getSDRCSVFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: {
        billFile: { file: string }
      } = await dispatch(apiCallAction(ACTION_CONST.API_GET_WHOLESALER_CDR_CSV,
        { cdrDocumentId: id }
      ))
      return `${response.billFile?.file}`;
    }
  }

  const tableData: any = useCDRTable(
    [{
      getterCreator: getSDRCSVFile,
      filenameGetter: cdr => validate(cdr.billName + '.csv'),
      downloadType: "application/csv",
    }]
  );

  return (
    <>
      <article className={cs('commissionTable cdrTable', `cdrTable-le--${theme}`)}>
            <CpxDownloadTable
              title={translations.titleCDRs()}
              headers={[
                { label: translations.tableHeadingDate(), sort: true, sortBy: 'cols.0', sortMethod: 'date' },
                { label: translations.tableHeadingName(), sort: true, sortBy: 'cols.1', sortMethod: 'string' },
              ]}
              tableData={tableData ? tableData : []}
              actionString={ACTION_CONST.API_GET_WHOLESALER_CDR_DATA}
              downloadsProps={[
                {
                  linkName: (
                    <>
                      <CpxDownload/>
                      {translations.csvLinkName()}
                    </>
                  ),
                  zipLinkName: translations.allCsvsName(),
                  zipFileName: translations.zipFileNameCsv(),
                }
              ]}
            />
      </article>
    </>
  )
}
