import { ACTION_CONST } from "../../constants/action.constants";
import { IAction } from "../../constants/types/types.constants";

const initialState = {

};

function loadingReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_CONST.API_LOADING_START:
      return {
        ...state,
        [action.data]: true,
      };
    case ACTION_CONST.API_LOADING_END:
      return {
        ...state,
        [action.data]: false,
      };
    default:
      return state;
  }
}

export default loadingReducer;
