import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { CpxTitle } from '../../../../core/components/title/title.component';
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useCurrentOrderType, useSelectFeatures, useSelectMainContracts, useSelectPartnerCustomer, useSelectPartners, useSelectUser, useSelectUserRole, useStep1Data } from "../../../redux/store";
import { NfoMainContractsTable } from "../../../components/customer/mainContracts/mainContract.component";
import { IPartnerCustomer } from "../../../constants/types/types.constants";
import { FEATURES_FLAG, ORDER_TYPE, USER_FUNCTIONS, USER_PARTNER, USER_WHOLESALER } from "../../../constants/configs/config.constants";
import { CpxButton } from "../../../../core/components/button.component";
import { deleteStepData, saveCurrentOrderType, saveCurrentStep, saveStep1Data, saveStep2Data } from "../../../redux/actions/step.action";
import { routes } from "../../../constants/routes/routes";
import { ReactComponent as Add } from "../../../assets/icons/add.svg";
import { useHistory } from "react-router-dom";
import cs from "classnames";
import { useTheme } from "../../../../core/utility/themeContext";
import "./myContracts.scss"
import { TextFieldModal } from "../../partner/customers/textFieldModal/textFieldModal.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoMyContractsPage = () => {
  const translations = useTranslations(TRANSLATIONS.myContracts);
  const migrationTranslation = useTranslations(TRANSLATIONS.customers);
  const internalClassName = "myContracts";
  const dispatch = useDispatch();
  const dispatchRes: AppDispatch = useDispatch();
  const mainContracts = useSelectMainContracts();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const theme = useTheme();
  const currentOrderType = useCurrentOrderType();
  const step1Data = useStep1Data();
  const history = useHistory();
  const features = useSelectFeatures();
  const user = useSelectUser();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState('');
  const usePartner = useSelectPartners();
  const role = useSelectUserRole();

  useEffect(() => {
      dispatch(apiCallAction(ACTION_CONST.API_GET_MAIN_CONTRACTS, {selectedPartnerCustomerId: selectedPartnerCustomer?.id}, true));
      dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ORDER_NEW_MAIN_CONTRACT }));
      dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_ORDER_ENTRY_MIGRATION }));
  }, [dispatch]);

  const getMigrationCustomerInfo = (customerId: string) => {
    setError('');

    if (!usePartner[0]?.voNumber) {
      setError(migrationTranslation.partnerVOErrorMessage())
      return;
    }

    dispatchRes(apiCallAction(ACTION_CONST.API_GET_OE_MIGRATION_CUSTOMER, { customerId: customerId, partnerVoNumber: usePartner[0]?.voNumber})).then((res: any) => {

      dispatch(deleteStepData());
      dispatch(saveCurrentStep(4));
      dispatch(saveCurrentOrderType(ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT));

      const mig = {
        migrationCustomerId: customerId,
        partnerVoNumber:usePartner[0]?.voNumber
      }

      dispatch(saveStep1Data(mig));

      dispatch(saveStep2Data({ siteAddresses: [...res?.customDataPortal?.siteAddresses, res.mainAddress], formdata: {} }))
      history.push(routes.partnerCustomersNewMainContract.path);
    })

  }

  return (
    <>
      {showModal && ( <TextFieldModal setShowModal={setShowModal} textFieldLabel={migrationTranslation.customerId()} onSubmition={getMigrationCustomerInfo} error={error} showOverview={true} setError={setError}></TextFieldModal>
      )}
      <div className={cs(`${internalClassName}`, 'customersTitle', `customersTitle-le--${theme}`)}>
        <CpxTitle
          title={translations.title()}
          name={
            selectedPartnerCustomer.companyName &&
            selectedPartnerCustomer.companyName
          }
          internalClassName={internalClassName}
        />
        <div className={"buttonGroup"}>
        {(role === USER_PARTNER || role === USER_WHOLESALER) && user?.functions?.includes(USER_FUNCTIONS.PORTAL_MIGRATE_CUSTOMER) && features.PORTAL_ORDER_ENTRY_MIGRATION &&
          <CpxButton
            buttonType={"white"}
            className={"buttonIcons"}
            onClick={() => {
              setError('')
              if (currentOrderType === ORDER_TYPE.ORDER_NEW_CUSTOMER  || (currentOrderType === ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT && (!step1Data?.migrationCustomerId || selectedPartnerCustomer?.id !== step1Data?.customer?.id))) {
                dispatch(deleteStepData());
                setShowModal(true);
              }else{
                history.push(routes.partnerCustomersNewMainContract.path);
              }
            }}
          >
            <Add/>
            {migrationTranslation.migration()}
          </CpxButton>
        }
        {features.PORTAL_ORDER_NEW_MAIN_CONTRACT && (role !== USER_WHOLESALER  || (usePartner && usePartner.length > 0 && usePartner[0].hasOffering)) &&
          <CpxButton
            className={"buttonIcons"}
            onClick={() => {
              if (currentOrderType === ORDER_TYPE.ORDER_NEW_CUSTOMER || (currentOrderType === ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT && (selectedPartnerCustomer?.id !== step1Data?.customer?.id || !!step1Data?.migrationCustomerId))) {
                dispatch(deleteStepData());
                dispatch(saveCurrentStep(4));
                dispatch(saveCurrentOrderType(ORDER_TYPE.ORDER_NEW_MAIN_CONTRACT));
              }

              history.push(selectedPartnerCustomer?.customerId ? routes.partnerCustomersNewMainContract.path : routes.customerNewMainContract.path);
            }}
          >
            <Add/>
            {translations.addButton()}
          </CpxButton>
        }
        </div>
      </div>
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
      <NfoMainContractsTable mainContracts={mainContracts}/>
    </>
  );
};
