import commonStyles from "../../../../../sass/base/common.module.scss";
import cs from "classnames";
import { CpxDropdownWithLabel } from "../../../../../../core/components/dropdown.component";
import { CpxInputWithLabel } from "../../../../../../core/components/inputWithLabel.component";
import { CpxPhoneInputWithLabel } from "../../../../../../core/components/phoneInputWithLabel.component";
import React from "react";
import { useTranslations } from "../../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../../constants/transitions/uiTranslations";
import { SelectOption } from "../../../../../constants/types/types.constants";
import { ID_SALUTATIONS } from "../../../../../constants/configs/config.constants";
import { useTheme } from "../../../../../../core/utility/themeContext";

export const MainDataForm = (
  {
    values, handleChange, touched, errors, legalFormOptions,
    salutationOptions, contactLanguageOptions, legalEntity, handleSalutation
  }: any) => {
  const theme = useTheme();
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const translationsCommon = useTranslations(TRANSLATIONS.common);

  const DEFAULT_SELECT_OPTION: SelectOption = {
    id: '',
    name: translationsCommon.defaultSelect(),
  }

  values = values?.customer
  touched = touched?.customer
  errors = errors?.customer

  return (
    <div className={commonStyles.columnContainer}>
      <div className={commonStyles.column}>
        <div className={cs('step', `step-le--${theme}`)}>
          <h4 className={`step-subtitle-le--${theme}`}>
            {translation.mainData()}
          </h4>
          <CpxDropdownWithLabel
            id="customer.legalForm.id"
            name="customer.legalForm.id"
            required={true}
            options={legalFormOptions}
            defaultOption={DEFAULT_SELECT_OPTION}
            value={values?.legalForm?.id}
            onChange={handleChange}
            error={errors?.legalForm?.id}
          >
            {translation.legalForm()}
          </CpxDropdownWithLabel>
          <CpxDropdownWithLabel
            id="customer.salutation.id"
            name="customer.salutation.id"
            required={true}
            options={salutationOptions}
            defaultOption={DEFAULT_SELECT_OPTION}
            onChange={handleSalutation}
            value={values?.salutation?.id}
            error={errors?.salutation?.id}

          >
            {translation.salutation()}
          </CpxDropdownWithLabel>
          <CpxInputWithLabel
            id="customer.companyName1"
            name="customer.companyName1"
            type="text"
            onChange={handleChange}
            value={values?.companyName1.trimStart()}
            error={touched?.companyName1 && errors?.companyName1}
          >
            {values.salutation?.id &&
            (parseInt(values.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName1() + " *" : translation.firstName() + " *"}

          </CpxInputWithLabel>
          <CpxInputWithLabel
            id="customer.companyName2"
            name="customer.companyName2"
            type="text"
            onChange={handleChange}
            value={values?.companyName2.trimStart()}
            error={touched?.companyName2 && errors?.companyName2}
          >
            {values.salutation?.id &&
            (parseInt(values.salutation?.id) === ID_SALUTATIONS.COMPANY) ?
                translation.companyName2() : translation.lastName() + " *"}
          </CpxInputWithLabel>
          <CpxInputWithLabel
            id="customer.taxIdentificationNumber"
            type="text"
            onChange={handleChange}
            value={values?.taxIdentificationNumber.trimStart()}
            error={touched?.taxIdentificationNumber && errors?.taxIdentificationNumber}
          >
            {translation.vatNr()}
          </CpxInputWithLabel>
          <CpxInputWithLabel
            id="customer.email"
            type="text"
            required={false}
            onChange={handleChange}
            value={values?.email.trimStart()}
            error={touched?.email && errors?.email}
          >
            {translation.email() + " *"}
          </CpxInputWithLabel>
          <CpxPhoneInputWithLabel
            fields={[{ name: 'customer.phone.countryCode', value: values?.phone?.countryCode, id: 'countryCode', placeholder: translation.countryCode() },
              { name: 'customer.phone.areaCode', value: values?.phone?.areaCode, id: 'areaCode', placeholder: translation.areaCode() },
              { name: 'customer.phone.cli', value: values?.phone?.cli, id: 'cli', placeholder: translation.cli() }
            ]}
            onChange={handleChange}
            errors={errors}
            touched={touched}
            label={translation.phoneNumber()}
          >
          </CpxPhoneInputWithLabel>
          <CpxDropdownWithLabel
            id="customer.language.id"
            name="customer.language.id"
            className={'customerContactLanguage'}
            onChange={handleChange}
            defaultOption={DEFAULT_SELECT_OPTION}
            value={values?.language?.id}
            options={contactLanguageOptions}
            error={errors?.language?.id}

          >
            {translation.contactLanguage() + " *"}
          </CpxDropdownWithLabel>
          {legalEntity?.id === 4 && <CpxInputWithLabel
            id="customer.companyNumber"
            type="text"
            onChange={handleChange}
            value={values?.companyNumber.trimStart()}
            error={touched?.companyNumber && errors?.companyNumber}
            /*disabled={true}*/
          >
            {translation.companyNumber() + " *"}
          </CpxInputWithLabel>}
        </div>
      </div>
    </div>
  )
}