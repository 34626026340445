import { validate } from "../../../../core/utils";
import { CpxDownloadTable } from "../../../../core/components/downloadTable.component";
import { CpxDownload } from "../../../../core/components/download.component";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../utils/helper/utils";
import { useBillsTable } from "../../../utils/bills/utils";
import { NfoAnonymousSwitch } from "../../../components/common/itemized/anonymousSwitch/anonymousSwitch.component";
import { NfoItemizedHelpBox } from "../../../components/common/itemized/itemizedHelpBox/itemizedHelpBox.component";
import { CpxTitle } from '../../../../core/components/title/title.component';
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import "./itemized.scss";
import React, { useEffect } from "react";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { useDispatch } from "react-redux";
import { useSelectAccounts } from "../../../redux/store";
import { Account } from "compax-api";
import cs from "classnames";
import { useTheme } from "../../../../core/utility/themeContext";

export const NfoItemizedPage = () => {
  const dispatch = useDispatch();
  const translations = useTranslations(TRANSLATIONS.itemized);
  const internalClassName = "itemized";
  const theme = useTheme();
  const account = useSelectAccounts()[0] as Account;

  useEffect(() => void dispatch(apiCallAction(ACTION_CONST.API_GET_ACCOUNTS)), [dispatch]);

  const getItemizedEnvFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: {
        itemizedBillFile: { file: string }
      } = await dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_FILE_ENV, { billId: id }))
      return `${response.itemizedBillFile?.file}`;
    }
  }

  const tableData: any = useBillsTable(
    [{
      getterCreator: getItemizedEnvFile,
      filenameGetter: bill => validate(bill.itemizedBillFileName),
      downloadType: "application/csv",
    }], true
  );

  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
          <article className={cs("itemizedTable", `itemizedTable-le--${theme}`)}>
            <CpxDownloadTable
              headers={[
                { label: translations.tableHeadingDate(), sort: true, sortBy: 'cols.0', sortMethod: 'date' },
                { label: translations.tableHeadingName(), sort: true, sortBy: 'cols.1', sortMethod: 'string' },
                { label: translations.tableHeadingBillNumber(), sort: true, sortBy: 'cols.2', sortMethod: 'string' },
              ]}
              tableData={tableData ? tableData : []}
              emptyStateTitle={translations.title()}
              actionString={ACTION_CONST.API_GET_ITEMIZED_BILLS}
              downloadsProps={[
                {
                  linkName: (
                    <>
                      <CpxDownload/>
                      {translations.csvLinkName()}
                    </>
                  ),
                  zipLinkName: translations.allCsvsName(),
                  zipFileName: translations.zipFileNameCsv(),
                },
              ]}
              downloadColHeader={translations.tableHeadingDownload()}
              additionalActions={
                <div className={cs("anonymizeAction", `anonymizeAction-le--${theme}`)}>
                  <NfoAnonymousSwitch account={account} className={"switch"}/>{" "}
                  {translations.anonymize()}
                  <NfoItemizedHelpBox />
                </div>
              }
            />
          </article>
    </>
  );
};
