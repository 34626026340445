import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { withReset } from '../utils/withReset';
import { createLangStore } from '../../core/uiLanguage/lang.slice';
import { LANGUAGES_ID } from '../constants/configs/config.constants';
import MainConfigConstants from './../../portal/constants/configs/main.config.constants';
import configReducer from './reducers/config.reducer';
import LoaderReducer from './reducers/loader.reducer';
import partnerCustomersReducer from './reducers/partnerCustomers.reducer';
import extensionsReducer from './reducers/extensions.reducer';
import productsReducer from './reducers/products.reducer';
import basketReducer from './reducers/basket.reducer';
import customerReducer from "./reducers/customer.reducer";
import partnersReducer from "./reducers/partners.reducer";
import apiCallStatusReducer from "./reducers/apiStatus.reducer";
import userReducer from "./reducers/user.reducer";
import errorReducer from "./reducers/error.reducer";
import partnerCommissionBillReducer from "./reducers/partnerCommissionBill.reducer";
import numberOrderReducer from "./reducers/numberOrder.reducer";
import editSipTrunkReducer from "./reducers/editSipTrunk.reducer";
import stepReducer from "./reducers/step.reducer";
import wholesalerSDRReducer from "./reducers/wholesalerSDRReducer";
import LookupsReducer from "./reducers/lookups.reducer";
import featuresReducer from "./reducers/features.reducer";
import partnerCustomContactPersonReducer from "./reducers/partnerCustomContactPerson.reducer";
import licensesReducer from "./reducers/licenses.reducer";
import changeProductReducer from "./reducers/changeProduct.reducer";
import wholesalerCDRReducer from "./reducers/wholesalerCDRReducer";
import loadingReducer from "./reducers/loading.reducer";

const rootReducer = combineReducers({
  apiCallStatus: apiCallStatusReducer,
  basket: basketReducer,
  config: configReducer,
  partnerCommissionBill: partnerCommissionBillReducer,
  wholesalerSDR: wholesalerSDRReducer,
  wholesalerCDR: wholesalerCDRReducer,
  customer: customerReducer,
  error: errorReducer,
  extensions: extensionsReducer,
  licenses: licensesReducer,
  productChange: changeProductReducer,
  numberOrder: numberOrderReducer,
  editSipTrunk: editSipTrunkReducer,
  lang: createLangStore<typeof LANGUAGES_ID>(
    navigator.languages[0].slice(0, 2) === 'de' ? 'DE' : 'EN'
  ).slice.reducer,
  loader: LoaderReducer,
  lookups: LookupsReducer,
  products: productsReducer,
  partners: partnersReducer,
  partnerCustomers: partnerCustomersReducer,
  user: userReducer,
  stepper: stepReducer,
  features: featuresReducer,
  partnerCustomContactPerson: partnerCustomContactPersonReducer,
  loading: loadingReducer,
});


const config = {
  key: `${MainConfigConstants.PERSIST_STORE.KEY}`,
  whitelist: MainConfigConstants.PERSIST_STORE.WHITELIST,
  blacklist: MainConfigConstants.PERSIST_STORE.BLACKLIST,
  storage,
};

const persistedReducer = persistReducer(config, rootReducer);
export const store = configureStore({
  reducer: withReset(persistedReducer),
  middleware: [thunk],
});


export type RootState = ReturnType<typeof store.getState>;

const createSelectorHook = <TSelected>(
  selector: (rootState: RootState) => TSelected
) => () => useSelector(selector);


//Customer
export const useSelectUser = createSelectorHook(state => state.user.data);
export const useSelectUserLegalEntity = createSelectorHook(state => state.user.data.legalEntity);
export const useSelectBills = createSelectorHook(state => state.customer?.myBills);
export const useSelectItemizedBills = createSelectorHook(state => state.customer?.myItemizedBills);
export const useSelectItemizedBillsTelefonica = createSelectorHook(state => state.customer?.myItemizedBillsTelefonica);

export const useSelectCustomer = createSelectorHook(state => state.customer?.myData);
export const useSelectAccounts = createSelectorHook(state => state.customer?.myAccounts);
export const useSelectContracts = createSelectorHook(state => state?.customer?.myContracts);
export const useSelectMainContracts = createSelectorHook(state => state?.customer?.myMainContracts);
export const useSelectedMainContract = createSelectorHook(state => state?.customer?.mySelectedMainContract);
export const useMenuItems = createSelectorHook(state => state?.customer?.myMenuItems);
export const useSelectContractsChargesSum = createSelectorHook(state => state?.customer?.myContractsChargeSum);
export const useSelectFraudLock = createSelectorHook(state => state.customer?.myFraudLock);
export const useSelectFraudLimits = createSelectorHook(state => state.customer?.myFraudLimits);
export const useSelectIsAnonymized = createSelectorHook(state => state.customer?.isAnonymized);
export const useSelectPackages = createSelectorHook(state => state.customer?.myPackages);
export const useSelectNumbers = createSelectorHook(state => state.customer?.myNumbers);
export const useSelectAddresses = createSelectorHook(state => state.customer?.myAddresses);
export const useSelectSipTrunks = createSelectorHook(state => state.customer?.mySipTrunks);
export const useSelectLicenses = createSelectorHook(state => state.customer?.myLicenses);
export const useSelectLicensesByServiceGroup = createSelectorHook(state => state.customer?.myLicensesByServiceGroup);

export const useSelectExtensions = createSelectorHook(state => state.extensions);
export const useLicensesBasket = createSelectorHook(state => state.licenses);

export const useProductChangeBasket = createSelectorHook(state => state.productChange);



export const useSelectPartners = createSelectorHook(state => state.partners);

export const useAddOptionToBasket = createSelectorHook(state => state.basket);

// Number Order
export const useSelectNumberOrder = createSelectorHook(state => state?.numberOrder);

// Order Sip Trunk
export const useSelectEditSipTrunk = createSelectorHook(state => state.editSipTrunk);

//Partner
export const useSelectPartnerCustomers = createSelectorHook(state => state.partnerCustomers.partnerCustomers);
export const usePartnerQuotes = createSelectorHook(state => state.partnerCustomers.partnerQuotes);
export const useSelectPartnerCustomerData = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer);
export const useSelectPartnerCustomerDataMenuItem = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.menuItems);
export const useSelectPartnerCustomer = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.partnerCustomer);
export const useSelectPartnerCustomerAccounts = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.accounts);
export const useSelectPartnerCustomerContract = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.contract);
export const useSelectPartnerCustomerExtensions = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.extensions);
export const useSelectPartnerCustomerMenuItems = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.menuItems);

export const useSelectPartnerCustomerNumbers = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.numbers);
export const useSelectPartnerCustomerAddress = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.address);
export const useSelectPartnerCustomerChargeSum = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.chargeSum);
export const useSelectPartnerCustomerCommunications = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.communications);
export const useSelectPartnerCustomerWorkflowsinfo = createSelectorHook(state => state.partnerCustomers.selectedPartnerCustomer?.workflowsinfo);
export const useSelectPartnerCommissionBill = createSelectorHook(state => state.partnerCommissionBill);
export const useSelectPartnerCustomContactPerson = createSelectorHook(state => state.partnerCustomContactPerson);

// Wholesaler
export const useSelectWholesalerSDR = createSelectorHook(state => state.wholesalerSDR);
export const useSelectWholesalerCDR = createSelectorHook(state => state.wholesalerCDR);


export const useSelectError = createSelectorHook(state => state.error);
export const useSelectApiCallStatus = createSelectorHook(state => state.apiCallStatus);

//Config
export const useSelectUserRole = createSelectorHook(state => state.config.userRole);
export const useSelectWholesaleModel = createSelectorHook(state => state.config.wholesaleModel);
export const useSelectMainProduct = createSelectorHook(state => state.config.mainProductId);
export const useSelectPkceCodeChallenge = createSelectorHook(state => state.config.pkceCodeChallenge);
export const useSelectPartnerCollapsibleStatus = createSelectorHook(state => state.config.partnerCollapsibleStatus);
export const useSelectLang = createSelectorHook(state => state.lang);
export const useSelectLoader = createSelectorHook(state => state.apiCallStatus.loading);

// Stepper
export const useCurrentStep = createSelectorHook(state => state.stepper.currentStep);
export const useCurrentOrderType = createSelectorHook(state => state.stepper.orderType);
export const useStep1Data = createSelectorHook(state => state.stepper.step1Data);
export const useStep2Data = createSelectorHook(state => state.stepper.step2Data);
export const useStep3Data = createSelectorHook(state => state.stepper.step3Data);
export const useShowHintModalAgain = createSelectorHook(state => state.stepper.showHintModalAgain);
export const useShowErrorModalAgain = createSelectorHook(state => state.stepper.showErrorModalAgain);
export const useStep4Data = createSelectorHook(state => state.stepper.step4Data);
export const useStep5Data = createSelectorHook(state => state.stepper.step5Data);
export const useStep6Data = createSelectorHook(state => state.stepper.step6Data);
export const useAllDocumentsUpload = createSelectorHook(state => state.stepper.allDocumentsUploaded);
export const useIsProductSelected = createSelectorHook(state => state.stepper.isProductSelected);
export const useOptionalSteps = createSelectorHook(state => state.stepper.optionalSteps);
export const useOrderEntryBasket = createSelectorHook(state => state.stepper.basket);
export const useStepperBillingInformationSelection = createSelectorHook(state => state.stepper.billingInformationSelection);
export const useStepperStep1DifferentContactPerson = createSelectorHook(state => state.stepper.step1IsDifferentContactPerson);
export const useStepperActivationSelection = createSelectorHook(state => state.stepper.activationDateSelection);
export const useStepperActivationDate = createSelectorHook(state => state.stepper.step4Data.activationDate);
export const useOEProducts = createSelectorHook(state => state.stepper.products)
export const useOEProductOptions = createSelectorHook(state => state.stepper.step4Data.productOptions);
export const useOEProductOptionsForSipTrunk = createSelectorHook(state => state.stepper.step4Data.productOptionsForSipTrunk);
export const useOEOptionsOfOptions = createSelectorHook(state => state.stepper.step4Data.optionsOfOptions);
//lookups
export const useSelectSalutations = createSelectorHook(state => state.lookups.salutations);
export const useSelectLegalForms = createSelectorHook(state => state.lookups.legalForms);
export const useSelectLanguage = createSelectorHook(state => state.lookups.languages);
export const useSelectCountries = createSelectorHook(state => state.lookups.country);
export const useSelectBillMedia = createSelectorHook(state => state.lookups.billMedia);
export const useSelectPaymentMode = createSelectorHook(state => state.lookups.paymentMode);
export const useSelectCarrier = createSelectorHook(state => state.lookups.carrier);
// Features
export const useSelectFeatures = createSelectorHook(state => state.features);

export const useSelectLoading = createSelectorHook(state => state.loading);



