import { CpxPaginatedTable } from "../../../../../core/components/paginatedTable.component";
import { NfoStatus } from "../../../../../core/components/status.component";
import { useSelectedMainContract, useSelectSipTrunks } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { getMainContract, getMainContractOfCustomerForPartner, useTranslations } from "../../../../utils/helper/utils";
import { ICONS, ID_CONTRACT_CHANNEL_OPTION, ID_CONTRACT_MS_TEAMS, ID_CPRODUCT_MS_TEAMS_DIRECT_ROUTING, ID_DATA_ENTITY, ID_STATUS_SERVICES } from "../../../../constants/configs/config.constants";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { ReactComponent as CheckDTS } from "../../../../assets/icons/check_dts.svg";
import { useTheme } from "../../../../../core/utility/themeContext";
import { CpxButton } from "../../../../../core/components/button.component";
import { CpxIcon } from "../../../../../core/components/icon.component";
import cs from "classnames";
import './sipTrunkTable.scss';
import React, { useState } from "react";
import { SipTrunkDirectRoutingModal } from "../sipTrunkDirectRoutingModal/sipTrunkDirectRoutingModal.component";
import { PortalContractData } from "compax-api";
import { ACTION_CONST } from "../../../../constants/action.constants";

export const NfoSipTrunkTable = ({ title, clickEditHandler }: any) => {
  const translationsEditSipTrunk = useTranslations(TRANSLATIONS.editSipTrunk);
  const translations = useTranslations(TRANSLATIONS.sipTrunk);
  const [showModal, setShowModal] = useState(false);
  const mainContract: PortalContractData = getMainContract();
  const selectedCustomer :any = getMainContractOfCustomerForPartner();
  const kNumber = mainContract ? mainContract.knumber : selectedCustomer.knumber;
  const selectedMainContract = useSelectedMainContract();

  const sipTrunks = useSelectSipTrunks();
  const theme = useTheme();
  const [selectedSipTrunk, setSelectedSipTrunk] = useState();

  const clipNoScreenings = sipTrunks?.map((sipTrunk: any) => {
    return sipTrunk.clipNoScreening === true;
  });

  const txtRecords = sipTrunks?.map((sipTrunk: any) => {
    return !!sipTrunk.sipTrunkOptions.find((option: any) => option.product.id === ID_CPRODUCT_MS_TEAMS_DIRECT_ROUTING);
  });

  let clipNoScreeningTitle: string | null;
  if (clipNoScreenings.includes(true)) {
    clipNoScreeningTitle = 'CLIP no screening';
  } else {
    clipNoScreeningTitle = null;
  }

  let txtRecordTitle: string | null;
  if (txtRecords.includes(true)) {
    txtRecordTitle = "TXT-Record";
  } else {
    txtRecordTitle = null;
  }

  const handleCnameSubmit = (sipTrunk: any) => {
    setShowModal(prev => !prev)
    setSelectedSipTrunk(sipTrunk)
  }

  const sipTrunkTableRowData = ((sipTrunk: any, index: number) => {

    return {
      id: "" + sipTrunk.id,
      collapsible: [
        <div>
          <b>Service ID</b>
          <p>{sipTrunk.id}</p>
        </div>,
        <></>,
        <></>,
        <></>,
        <></>,
        <>
          {sipTrunk?.sipTrunkOptions
            ?.filter((api: any) => api.serviceType?.id === Number(ID_CONTRACT_CHANNEL_OPTION))
            .map((sipTrunkOption: any, index: number) => {
              return (
                <>
                  <CpxButton
                    disabled={!(!sipTrunkOption?.successor && sipTrunkOption.status.id !== ID_STATUS_SERVICES.ORDERED && sipTrunk.status.id !== ID_STATUS_SERVICES.BARRED && sipTrunkOption.serviceType?.id === Number(ID_CONTRACT_CHANNEL_OPTION))}
                    onClick={() => clickEditHandler(sipTrunk.id, sipTrunk.product.id, sipTrunk.sipTrunkOptions[0].product.id, `${sipTrunk?.contractName} ${index + 1}`)}>{translationsEditSipTrunk.edit()}</CpxButton>
                </>
              );
            })}
        </>,
      ],
      cellData: [
        <p
          className={cs("tableFirstChild", `tableFirstChild-font-le--${theme}`)}
        >
          {sipTrunk?.contractName}
        </p>,
        <>
          {sipTrunk?.sipTrunkOptions?.filter((api: any) => {
              return api.serviceType?.id === Number(ID_CONTRACT_CHANNEL_OPTION)
            }
          ).map((sipTrunkOption: any, index: number) => {
            return (
              <div key={index}>
                {sipTrunkOption.contractName}
              </div>
            );
          })}
        </>,
        <>
          {theme === "dts" ? (sipTrunk?.clipNoScreening && <CheckDTS/>) : (sipTrunk?.clipNoScreening && <Check/>)}
        </>,
        /*<>
          {(sipTrunk?.sipTrunkOptions.find((option: any) => option.serviceType.id == ID_CONTRACT_MS_TEAMS) && sipTrunk.teamsWFOpen) && sipTrunk?.teamsTxtRecord && <CpxButton
            buttonType={"secondary"} className={cs("tableFirstChild", `tableFirstChild-font-le--${theme}`, 'txt-record')}>
            {`${sipTrunk?.teamsTxtRecord}`}
          </CpxButton>}
        </>,*/
        <>
          {sipTrunk.sipTrunkOptions?.find((item: any) => item.serviceGroup.id === 2).quantity}
        </>,
        <>
          {(sipTrunk?.sipTrunkOptions.find((option: any) => option.serviceType.id == ID_CONTRACT_MS_TEAMS) /*&& sipTrunk.teamsWFOpen*/) &&
            /*<CpxButton className={'buttonIcons'} onClick={() => handleCnameSubmit(sipTrunk)}>
              <CpxIcon icon={ICONS.ADD}/> <p>CNAME</p>
            </CpxButton>*/
            theme === "dts" ? (sipTrunk?.msTeams && <CheckDTS/>) : (sipTrunk?.msTeams && <Check/>)
          }
        </>,
        <>
          {sipTrunk?.sipTrunkOptions?.filter((api: any) => {
              return api.serviceType?.id === Number(ID_CONTRACT_CHANNEL_OPTION)
            }
          ).map((sipTrunkOption: any, index: number) => {
            return (
              <div key={index}>
                {sipTrunkOption?.successor ?
                  <div className={'successor'}>
                    <CpxIcon icon={ICONS.STATUS.ORDERED}/><p>{translations.markedForModification()}</p>
                  </div> :
                  <NfoStatus status={sipTrunkOption.status.id === ID_STATUS_SERVICES.ORDERED ? sipTrunkOption.status : sipTrunk.status} entity={{ id: ID_DATA_ENTITY.SERVICE }}/>}
              </div>
            );
          })}
        </>,
      ],
    }
  });

  return (
    <>
    {showModal && <SipTrunkDirectRoutingModal setShowModal={setShowModal} selectedSipTrunk={selectedSipTrunk} />}
      <article className={cs('customerSipTrunkTable', `${'customerSipTrunkTable'}-le--${theme}`)}>
        <CpxPaginatedTable
          id="sipTrunkTable"
          tableHeader={[
            translations.name(),
            translations.languagePack(),
            clipNoScreeningTitle,
            translationsEditSipTrunk.voiceChannels(),
            /*txtRecordTitle,*/
            translations.msTeams(),
            translations.status(),
          ]}
          tableData={Array.isArray(sipTrunks) && sipTrunks?.map(sipTrunkTableRowData)}
          actionString={ACTION_CONST.API_GET_SIPTRUNK}
          emptyStateProps={{
            pageTitle:title,
            boxStyling:true
          }}
        />
      </article>
    </>
  );
};
