import { PortalContractData } from 'compax-api';
import { useTheme } from '../../../../core/utility/themeContext';
import { useSelectPartnerCustomer } from "../../../redux/store";
import { SearchBox } from "../../common/search/searchBox.component";
import cs from "classnames";
import { CpxPaginatedTable } from "../../../../core/components/paginatedTable.component";
import React, { useEffect, useState } from "react";
import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { NfoStatus } from "../../../../core/components/status.component";
import { ICONS, ID_DATA_ENTITY } from "../../../constants/configs/config.constants";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { editNotesBody } from "../../../utils/helper/requestBodyHelper";
import { CpxIcon } from "../../../../core/components/icon.component";
import { CpxButton } from "../../../../core/components/button.component";
import { setSelectedContract } from "../../../redux/actions/config.action";
import { routes } from "../../../constants/routes/routes";
import { useHistory } from "react-router-dom";
import { IPartnerCustomer, ToastTypes } from "../../../constants/types/types.constants";
import "./mainContract.scss"
import { CpxInputWithLabel } from "../../../../core/components/inputWithLabel.component";
import { useToast } from "../../common/header/toast/toast.component";

type Props = {
  mainContracts: PortalContractData[];
};

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const NfoMainContractsTable = ({ mainContracts }: Props) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const internalClassName = "mainContractsTable";
  const translations = useTranslations(TRANSLATIONS.myContracts);
  const history = useHistory();
  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const { showToast } = useToast();

  const [searchedItems, setSearchedItems]: any = useState(mainContracts);
  const [editNotes, setEditNotes] = useState(new Map<number, boolean>(mainContracts.map(contract => [Number(contract.contractId), false])));
  const [editedContracts, setEditedContracts] = useState(new Map<number, PortalContractData>(mainContracts.map(contract => [Number(contract.contractId), contract])));

  useEffect(() => {
    setEditedContracts(new Map<number, PortalContractData>(mainContracts.map(contract => [Number(contract.contractId), contract])));
  }, [mainContracts])


  const mainContractsTableRowData = (mainContract: PortalContractData) => {
    if (mainContracts != null) {
      return {
        id: '' + mainContract.contractId,
        cellData: [
          <div
            className={cs(
              'tableFirstChild',
              `tableFirstChild-font-le--${theme}`
            )}
          >
            <a className={cs(`${internalClassName}-knumber`, `${internalClassName}-knumber-le--${theme}`)} onClick={() => onMainContractClick(mainContract)}>{mainContract?.productCategory?.id === 14 ? mainContract.id : mainContract.knumber}</a>
          </div>,
          <p style={{ overflowWrap: 'anywhere' }}>{mainContract.contractName}</p>,
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={mainContract.status}
          />,
          <div className={cs(`${internalClassName}-notes`, `${internalClassName}-notes-le--${theme}`)}>
            {editNotes.get(Number(mainContract.contractId))
              ? <>
                <CpxInputWithLabel
                  id={"contractNotes"}
                  name={"contractNotes"}
                  value={editedContracts.get(Number(mainContract.contractId))?.notes}
                  onChange={(event: any) => updateContractsMap(Number(mainContract.contractId), event.target.value)}
                  type="text"
                  maxLength={4000}
                  autoFocus
                  onKeyDown={e => e.key === 'Enter' && submitNotesWithoutFormik(editedContracts.get(Number(mainContract.contractId)))}
                />
                <CpxButton className={"save-btn"} type={'button'} onClick={(event) => submitNotesWithoutFormik(editedContracts.get(Number(mainContract.contractId)))}>{translations.save()}</CpxButton>
                <CpxButton className={cs('iconOnly', `${internalClassName}-cross-btn ${internalClassName}-cross-btn-le--${theme}`)} type={'button'} onClick={() => updateNotesEdit(Number(mainContract.contractId), false)}><CpxIcon
                  icon={ICONS.CROSS}/></CpxButton>
              </>
              : <>
                <p>{mainContract.notes ? mainContract.notes : <span>{translations.emptyNotes()}</span>}</p>
                <CpxButton className={"iconOnly"} type={'button'} onClick={() => updateNotesEdit(Number(mainContract.contractId), true)}><CpxIcon icon={ICONS.EDIT}/></CpxButton>
              </>
            }
          </div>

        ],
      };
    }
  };


  const submitNotesWithoutFormik = (contract: PortalContractData | undefined) => {
    if (contract !== undefined) {
      dispatch(apiCallAction(ACTION_CONST.API_PUT_CONTRACT_NOTES, { ...editNotesBody(contract.notes || ""), contractId: contract.contractId, selectedPartnerCustomerId: selectedPartnerCustomer.customerId }))
        .then(() => {
          showToast(ToastTypes.SUCCESS, translations.changeNotesSuccess());
          dispatch(apiCallAction(ACTION_CONST.API_GET_MAIN_CONTRACTS, { selectedPartnerCustomerId: selectedPartnerCustomer?.id }, true)).then(() => {
              updateNotesEdit(Number(contract.contractId), false);
            }
          )
        })
    }
  }

  const getSearchedItems = (data: any) => {
    setSearchedItems(data);
  };

  const updateNotesEdit = (key: number, value: boolean) => {
    setEditNotes((prevMap) => {
      const tmpEditNotes = new Map(prevMap);
      tmpEditNotes.set(key, value);
      return tmpEditNotes;
    })
  }

  const updateContractsMap = (key: number, value: string) => {
    setEditedContracts((prevMap) => {
      const tmpEditNotes = new Map(prevMap);
      tmpEditNotes.set(key, { ...tmpEditNotes.get(key), notes: value });
      return tmpEditNotes;
    })
  }

  const onMainContractClick = (mainContract: PortalContractData) => {
    dispatch(setSelectedContract(mainContract))
    history.push(selectedPartnerCustomer?.customerId ? routes.partnerCustomersContract.path : routes.customerContractDetails.path);
  };


  return (
    <>
      {mainContracts && (
        <SearchBox
          theme={theme}
          placeholder={
            translations.kNumber() +
            ', ' +
            translations.productName()
          }
          id={'mainContractsTable'}
          data={mainContracts}
          getFilteredItems={getSearchedItems}
          shouldBeSearched={['knumber', 'contractName']}
        />
      )}
      <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
        <CpxPaginatedTable
          id="mainContractsTable"
          tableHeader={[
            { label: translations.kNumber(), sort: true, sortBy: 'knumber', sortMethod: 'string' },
            { label: translations.productName(), sort: true, sortBy: 'contractName', sortMethod: 'string' },
            { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
            { label: translations.notes(), sort: false, sortBy: 'notes', sortMethod: 'string' },
          ]}
          tableData={searchedItems.map((contract: PortalContractData, index: number) => mainContractsTableRowData(contract))}
          itemsPerPage={[10, 20]}
          pagination="bottom"
          actionString={ACTION_CONST.API_GET_MAIN_CONTRACTS}
          emptyStateProps={{
            pageTitle: translations.contracts(),
            subtitle: translations.emptyStateSubTitle(),
            boxStyling: true
          }}
          itemsPerPageLabel={translations.paginationSelectLabel()}
          sliceToString={(from: number, to: number, total: number) =>
            translations.paginationSliceInfo(from + '', to + '', total + '')
          }
          rowData={searchedItems && searchedItems}
          getSortedRowsBack={(sortedRows: any) => setSearchedItems(sortedRows?.slice())}
        />
      </article>
    </>
  );

};
