import { useTranslations } from "../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../constants/transitions/uiTranslations";
import { useBillsTable } from "../../../utils/bills/utils";
import { validate } from "../../../../core/utils";
import { CpxDownloadTable } from "../../../../core/components/downloadTable.component";
import { CpxDownload } from "../../../../core/components/download.component";
import { CpxTitle } from "../../../../core/components/title/title.component";
import { CpxSubtitle } from "../../../../core/components/title/subtitle.component";
import "./bills.scss";
import { useDispatch } from "react-redux";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import React from "react";
import cs from "classnames";
import { useTheme } from "../../../../core/utility/themeContext";

export const NfoBillsPage = () => {
  const translations = useTranslations(TRANSLATIONS.bills);
  const internalClassName = "bills";
  const theme = useTheme();
  const dispatch = useDispatch();

  const getBillFile = (id: string) => {
    return async () => {
      // @ts-ignore
      const response: {
        billFile: { file: string }
      } = await dispatch(apiCallAction(ACTION_CONST.API_GET_BILL_FILE, { billId: id }))
      return `${response.billFile?.file}`;
    }
  }

  const tableData: any = useBillsTable([
    {
      getterCreator: getBillFile,
      filenameGetter: bill => validate(bill.billFileName),
      downloadType: "application/pdf",
    },
  ]);

  return (
    <>
      <CpxTitle
        title={translations.title()}
        internalClassName={internalClassName}
      />
      <CpxSubtitle
        subtitle={translations.subheading()}
        internalClassName={internalClassName}
      />
        <article className={cs("billsTable", `billsTable-le--${theme}`)}>
          {<CpxDownloadTable
            headers={[
              { label: translations.tableHeadingDate(), sort: true, sortBy: 'cols.0', sortMethod: 'date' },
              { label: translations.tableHeadingName(), sort: true, sortBy: 'cols.1', sortMethod: 'string' },
              { label: translations.tableHeadingBillNumber(), sort: true, sortBy: 'cols.2', sortMethod: 'string' },
            ]}
            tableData={tableData ? tableData : []}
            emptyStateTitle={translations.title()}
            actionString={ACTION_CONST.API_GET_BILLS}
            downloadsProps={[
              {
                linkName: (
                  <>
                    <CpxDownload/>
                    {translations.pdfLinkName()}
                  </>
                ),
                zipLinkName: translations.allPdfsName(),
                zipFileName: translations.zipFileNamePdf(),
              },
            ]}
            downloadColHeader={translations.tableHeadingDownload()}
          />}
        </article>
    </>
  );
};
